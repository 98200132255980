





























































































import { Vue, Component } from 'vue-property-decorator';
@Component({
  components: {}
})
export default class Todo extends Vue {
  formData: any = {};
  rules: any = {
    c1: [
      { required: true, message: '模型名称不能为空', whitespace: true, trigger: 'blur' },
      { max: 25, message: '最大长度不能超过25', trigger: 'blur' }
    ],
    c2: [{ required: true, message: '模型编码不能为空', whitespace: true, trigger: 'blur' }],
    c3: [{ required: true, message: '业务类型不能为空', whitespace: true, trigger: 'change' }]
  };

  modelFieldList: any = [];

  addList() {
    this.modelFieldList.push({});
  }

  removeList(index: number) {
    this.modelFieldList.splice(index, 1);
  }
}
